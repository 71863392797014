import React from 'react';

import BrokersRouting from './BrokerRouting';
import StaffRouting from './StaffRouter';
import { useIsStaff } from 'utils/hooks/useIsStaff';
import { EmailVerificationGuard } from '../guards/EmailVerificationGuard';
import { OnboardingGuard } from '../guards/OnboardingGuard';
import { MfaGuard } from '../guards/MfaGuard';

const Routing = () => {
  const isStaff = useIsStaff();

  return isStaff ? (
    <MfaGuard>
      <StaffRouting />
    </MfaGuard>
  ) : (
    <EmailVerificationGuard>
      <OnboardingGuard>
        <BrokersRouting />
      </OnboardingGuard>
    </EmailVerificationGuard>
  );
};

export default Routing;
