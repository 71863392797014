import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';

import styles from './styles.module.less';
import WelcomeMessage from 'components/WelcomeMessage/WelcomeMessage';

const { Sider, Content } = Layout;

const SignUpLayout = ({ children }) => (
  <Layout className={styles.wrapper}>
    <Sider width="60%" className={styles.aside}>
      <WelcomeMessage />
    </Sider>
    <Content className={styles.content}>{children}</Content>
  </Layout>
);

SignUpLayout.propTypes = {
  children: PropTypes.node,
};

export default SignUpLayout;
