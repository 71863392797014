import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import AuthLayout from 'HOC/AuthLayout';
import SuspenseWrapper from 'HOC/SuspenseWrapper';
import { PrivateRoute } from './PrivateRoute';
import Routing from './Routing';

import { ROUTING } from 'config';
import SignUpLayout from 'HOC/SignUpLayout';
import VerifyEmailLayout from 'HOC/VefiyEmailLayout';
import OnboardingLayout from 'HOC/OnboardingLayout';
import { EmailVerificationGuard } from './guards/EmailVerificationGuard';

const SignIn = React.lazy(() =>
  import('containers/Common/Authorization/SignIn'),
);
const SetupMfa = React.lazy(() => import('containers/Staff/SetupMfa'));

const SignUp = React.lazy(() => import('containers/Brokers/SignUp'));

const EmailVerification = React.lazy(() =>
  import('containers/Brokers/EmailVerification'),
);

const Onboarding = React.lazy(() => import('containers/Brokers/Onboarding'));

const NewPassword = React.lazy(() =>
  import('containers/Common/Authorization/NewPassword'),
);

const ResetPassword = React.lazy(() =>
  import('containers/Common/Authorization/ResetPassword'),
);

const Routes = () => (
  <Switch>
    <Route exact path={ROUTING.SIGN_UP}>
      <SignUpLayout>
        <SuspenseWrapper position="center">
          <SignUp />
        </SuspenseWrapper>
      </SignUpLayout>
    </Route>
    <PrivateRoute exact path={ROUTING.VERIFY_EMAIL}>
      <VerifyEmailLayout>
        <SuspenseWrapper position="center">
          <EmailVerification />
        </SuspenseWrapper>
      </VerifyEmailLayout>
    </PrivateRoute>
    <PrivateRoute exact path={ROUTING.ONBOARDING}>
      <EmailVerificationGuard>
        <OnboardingLayout>
          <SuspenseWrapper position="center">
            <Onboarding />
          </SuspenseWrapper>
        </OnboardingLayout>
      </EmailVerificationGuard>
    </PrivateRoute>
    <Route exact path={ROUTING.LOGIN}>
      <AuthLayout>
        <SuspenseWrapper position="center">
          <SignIn />
        </SuspenseWrapper>
      </AuthLayout>
    </Route>
    <Route exact path={ROUTING.NEWPASSWORD}>
      <AuthLayout type="newPassword">
        <SuspenseWrapper position="center">
          <NewPassword />
        </SuspenseWrapper>
      </AuthLayout>
    </Route>
    <Route exact path={ROUTING.RESET}>
      <AuthLayout type="resetPassword">
        <SuspenseWrapper position="center">
          <ResetPassword />
        </SuspenseWrapper>
      </AuthLayout>
    </Route>
    <PrivateRoute exact path={ROUTING.SETUP_MFA}>
      <AuthLayout type="mfaSetup">
        <SuspenseWrapper position="center">
          <SetupMfa />
        </SuspenseWrapper>
      </AuthLayout>
    </PrivateRoute>
    <PrivateRoute path={'/:section/:sectionChild?'}>
      <Routing />
    </PrivateRoute>
    <Redirect from="*" to={ROUTING.DASHBOARD} />
  </Switch>
);
export default Routes;
