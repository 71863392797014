import React from 'react';
import { Redirect } from 'react-router';
import { ROUTING } from 'config';
import { useIsStaff } from 'utils/hooks/useIsStaff';
import { useUser } from 'utils/hooks/useUser';

export const EmailVerificationGuard = ({ children }) => {
  const { isEmailVerified } = useUser();

  const isStaff = useIsStaff();

  if (isStaff || isEmailVerified) {
    return children;
  }

  return (
    <Redirect
      to={{
        pathname: ROUTING.VERIFY_EMAIL,
      }}
    />
  );
};
