import React from 'react';
import { FmLogo } from 'components/Icons/logos';
import { Typography } from 'antd';
import styles from './styles.module.less';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const { Title, Text } = Typography;

const WelcomeMessage = ({ className }) => (
  <div className={clsx(className, styles.wrapper)}>
    <div className={styles.logoWrapper}>
      <FmLogo textColorOverride="#6A67CF" />
    </div>
    <div className={styles.textings}>
      <Title level={1} className={styles.title}>
        Join Fusion Partners
      </Title>
      <Text className={styles.subTitle}>
        {`In a few clicks you'll be partnering with an industry leader in low-cost trading`}
      </Text>
    </div>
  </div>
);

WelcomeMessage.propTypes = {
  className: PropTypes.string,
};

export default WelcomeMessage;
