import React from 'react';
import { Layout } from 'antd';
import styles from './styles.module.less';
import PropTypes from 'prop-types';

const VerifyEmailLayout = ({ children }) => (
  <Layout className={styles.wrapper}>{children}</Layout>
);

VerifyEmailLayout.propTypes = {
  children: PropTypes.node,
};

export default VerifyEmailLayout;
