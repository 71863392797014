import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Input, Select } from 'antd';

import DropdownSelect from 'components/Common/Input/DropdownSelect';
import Identity from 'components/Common/Identifier';

import { getPhoneObject } from 'utils/helpers';
import { COUNTRY_CODES_MAP } from 'utils/countries';

import styles from './styles.module.less';

const { Option } = Select;

function reducer(state, action) {
  switch (action.type) {
    case 'countryCode':
      const phoneCode = COUNTRY_CODES_MAP[action.value]?.code;

      return {
        phoneCode,
        phoneNumber: phoneCode ?? '',
        countryCode: action.value,
      };
    default: {
      return getPhoneObject(action.value, action.initialCounty);
    }
  }
}

const PhoneInput = ({
  value,
  onSubmit,
  onChange,
  className,
  initialCounty,
}) => {
  const [{ phoneNumber, countryCode }, dispatch] = useReducer(
    reducer,
    getPhoneObject(value, initialCounty),
  );

  const onNumberChange = useCallback(
    (event) => {
      dispatch({ value: event.target.value, initialCounty });
      onChange(event.target.value);
    },
    [onChange],
  );

  const countries = useMemo(() => Object.keys(COUNTRY_CODES_MAP), []);

  const onSelect = useCallback((value) => {
    dispatch({ type: 'countryCode', value });
  }, []);

  useEffect(() => {
    if (value) {
      dispatch({ value, initialCounty });
    }
  }, [value]);

  useEffect(() => {
    if (initialCounty) {
      dispatch({ type: 'countryCode', value: initialCounty });
    }
  }, [initialCounty]);

  return (
    <Input
      id="tel"
      addonBefore={
        <DropdownSelect
          showSearch={false}
          value={countryCode || 'Country'}
          onSelect={onSelect}
          placeholder="Country"
        >
          {countries.map((countryCode) => (
            <Option
              key={countryCode}
              value={countryCode}
              className={styles.option}
            >
              <Identity
                name="Country"
                value={countryCode}
                className={styles.flag}
              />
            </Option>
          ))}
        </DropdownSelect>
      }
      className={clsx(styles.input, className)}
      onChange={onNumberChange}
      value={phoneNumber}
      onSubmit={onSubmit}
    />
  );
};

PhoneInput.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  initialCounty: PropTypes.string,
};

export default PhoneInput;
