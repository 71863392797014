import React from 'react';
import { Redirect } from 'react-router';
import { ROUTING } from 'config';
import { useUser } from 'utils/hooks/useUser';
import { useOnboardingStatus } from 'modules/shared/onboarding/hooks/useOnboardingStatus';
import Loader from 'components/Common/Loader';
import { OnboardingStatus } from 'modules/shared/onboarding/constants/onboardingStatus';

export const OnboardingGuard = ({ children }) => {
  const { id: userId } = useUser();

  const { onboardingStatus, isOnboardingStatusLoading } =
    useOnboardingStatus(userId);

  if (isOnboardingStatusLoading) {
    return (
      <div style={{ minHeight: '100vh' }}>
        <Loader type="center" />
      </div>
    );
  }

  if (onboardingStatus === OnboardingStatus.IN_PROGRESS) {
    return <Redirect to={ROUTING.ONBOARDING} />;
  }

  return children;
};
