import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'utils/queryKeys';
import { api } from 'api';

export const useOnboardingStatus = (userId) => {
  const { data: onboardingStatus, isLoading: isOnboardingStatusLoading } =
    useQuery({
      queryKey: queryKeys.onboardingStatus,
      queryFn: async () => {
        const { data } = await api.getOnboardingStatus(userId);

        return data;
      },
      enabled: Boolean(userId),
    });

  return {
    onboardingStatus,
    isOnboardingStatusLoading,
  };
};
