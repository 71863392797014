import { Layout } from 'antd';
import styles from './styles.module.less';
import PropTypes from 'prop-types';
import React from 'react';

const OnboardingLayout = ({ children }) => (
  <Layout className={styles.wrapper}>{children}</Layout>
);

OnboardingLayout.propTypes = {
  children: PropTypes.node,
};

export default OnboardingLayout;
